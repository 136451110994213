import {get} from '../utils/axios.wrapper';
import {useEffect, useState} from 'react';
import {useLoading} from '../utils/LoadingContext';
import Input from '../components/Input';
import _ from 'lodash';
import LottieLoader from '../components/LottieLoader';
import {useNavigate} from 'react-router-dom';
import PlayerLayout from '../components/PlayerLayout';

function Play() {
    const {loading, setLoadingState} = useLoading();
    const [categories, setCategories] = useState(true);

    const navigate = useNavigate();

    const getCategories = _.debounce(function (keyword = null) {
        get(`${process.env.REACT_APP_API_BASE_URL}/categories/all`, {channel_name: keyword}, setLoadingState).then((response) => {
            setCategories(response.data);
        });
    }, 1500);

    const getInitialCategories = () => {
        get(`${process.env.REACT_APP_API_BASE_URL}/categories/all`, null, setLoadingState).then((response) => {
            setCategories(response.data);
        });
    };

    useEffect(() => {
        getInitialCategories();
    }, []);

    return (
        <PlayerLayout>
            {loading ? <LottieLoader/> : null}
            <div style={{backgroundImage: "url('/images/bg.png')"}}
                 className="w-full h-screen bg-cover flex justify-center">

                <div className="container p-1 max-w-6xl">

                    <div className="flex flex-col gap-4 items-center justify-center h-[20%]">
                        <img src="/images/logo.png" alt="Logo" className="w-32"/>
                        <Input
                            label=""
                            placeholder="Search..."
                            onChange={(e) => getCategories(e.target.value)}
                        />
                    </div>

                    <div
                        className="categories flex flex-col gap-24 items-start justify-start h-[80%] py-12 overflow-auto px-2">

                        {
                            categories.length ?
                                categories.map((category) => (
                                    <div key={`category_${category.id}`}
                                         className="category flex flex-col  gap-4 items-start justify-start text-start">
                                        <h6 className="text-[rgb(112,104,200)] font-bold text-2xl px-2 py-1 border-2 border-[rgb(142,133,231)] rounded-full bg-white">{category.name}</h6>

                                        <div className="channels flex flex-row gap-4">
                                            {
                                                category?.channels?.length ?
                                                    category?.channels?.map((channel) => (
                                                        <div
                                                            key={`channel_${channel.id}`}
                                                            onClick={() => navigate('/player', {
                                                                state: {
                                                                    link: channel?.stream_link,
                                                                    channel_id: channel.id
                                                                }
                                                            })}
                                                            className={`transition duration-300 ease-in-out cursor-pointer hover:scale-110 channel border-4 p-4 rounded-3xl w-32 h-32 relative bg-cover bg-white ${channel?.has_error ? 'border-[rgb(240,55,80)]' : 'border-[rgb(142,133,231)]'}`}
                                                            style={{backgroundImage: `url('${channel?.icon?.url}')`}}>
                                                            <div
                                                                className={`channel-name px-2 py-1 rounded-full absolute -bottom-6 left-0 right-0 ${channel?.has_error ? 'bg-[rgb(240,55,80)]' : 'bg-[rgb(142,133,231)]'}`}>
                                                                <h6 className="text-white font-bold text-center">{channel.name}</h6>
                                                            </div>
                                                        </div>
                                                    )) : null
                                            }
                                        </div>
                                    </div>
                                )) : null
                        }

                    </div>
                </div>

            </div>
        </PlayerLayout>
    );
}

export default Play;

import {history} from '../utils/router';
import {useLocation, useNavigate} from 'react-router-dom';

export default function PlayerLayout(props) {
    const {children} = props;

    history.navigate = useNavigate();
    history.location = useLocation();

    return (
        <>
            {children}
        </>
    );
}
import {PhotoIcon} from '@heroicons/react/24/outline';

export default function FileUpload(props) {
    const {name, id, label, helperText, onChange, image, labelColor = 'text-slate-800'} = props;

    return (
        <div>
            <label htmlFor={id} className={`block leading-6 font-bold mb-1 ${labelColor}`}>
                {label}
            </label>
            <div className="flex items-center gap-x-3">
                <PhotoIcon className="h-14 w-14 text-gray-300" aria-hidden="true"/>
                <div className="flex text-sm leading-6 text-gray-400">
                    <label
                        htmlFor={id}
                        className="transition ease-in-out duration-300 hover:shadow-md rounded-md px-2 py-2 text-sm font-semibold shadow-sm border border-slate-400 bg-white text-slate-800 hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-200 cursor-pointer"
                    >
                        <span>Upload a file</span>
                        <input id={id} name={name} type="file" onChange={onChange} className="sr-only"/>
                    </label>
                </div>
            </div>
            {
                image ?
                    <div className="selected-image mt-2">
                        <img src={image} alt="Selected Image" className="h-12"/>
                    </div> : null
            }
            {
                helperText ?
                    <p className="mt-2 text-sm text-slate-500" id="email-description">
                        {helperText}
                    </p> : null
            }
        </div>
    );
}

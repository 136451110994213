export default function Button(props) {
    const {onClick, label = 'Button', style = 'default', type = 'button', disabled = false} = props;

    const _default = `bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${disabled ? '!bg-slate-400 !text-white' : null}`;
    const success = `bg-green-600 text-white hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 ${disabled ? '!bg-slate-400 !text-white' : null}`;
    const warning = `bg-yellow-600 text-white hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600 ${disabled ? '!bg-slate-400 !text-white' : null}`;
    const danger = `bg-red-600 text-white hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 ${disabled ? '!bg-slate-400 !text-white' : null}`;
    const ghost = `border border-slate-400 bg-white text-slate-800 hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-200 ${disabled ? '!bg-slate-400 !text-white' : null}`;

    return (
        <button
            disabled={disabled}
            type={type}
            onClick={onClick}
            className={`transition ease-in-out duration-300 hover:shadow-md rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${style === 'default' ? _default : style === 'success' ? success : style === 'warning' ? warning : style === 'danger' ? danger : style === 'ghost' ? ghost : null }`}
        >
            {label}
        </button>
    )
}

import {createContext, useContext, useState} from 'react';

const LoadingContext = createContext();

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({children}) => {
    const [loading, setLoading] = useState(false);

    const setLoadingState = (status) => {
        setLoading(status);
    };

    return (
        <LoadingContext.Provider value={{loading, setLoadingState}}>
            {children}
        </LoadingContext.Provider>
    );
};
export default function Checkbox(props) {
    const {name, id, label = 'Checkbox', helperText, onChange, checked} = props;

    return (
        <fieldset>
            <div className="mt-6 space-y-6">
                <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                        <input
                            id={id}
                            name={name}
                            type="checkbox"
                            checked={checked}
                            onChange={onChange}
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                    </div>
                    <div className="text-sm">
                        <label htmlFor={id} className="block leading-6 font-bold">
                            {label}
                        </label>
                        {
                            helperText ?
                                <p className="text-gray-500">{helperText}</p>
                                : null
                        }
                    </div>
                </div>
            </div>
        </fieldset>
    );
}

import {useEffect, useState} from 'react';
import Layout from '../../components/Layout';
import Input from '../../components/Input';
import FileUpload from '../../components/FileUpload';
import Button from '../../components/Button';
import {get, patch} from '../../utils/axios.wrapper';
import {toast} from 'react-toastify';
import {useLoading} from '../../utils/LoadingContext';
import Checkbox from '../../components/Checkbox';
import {useNavigate, useParams} from 'react-router-dom';
import Select from '../../components/Select';

function EditChannel() {
    const {setLoadingState} = useLoading();
    const [categories, setCategories] = useState("");
    const [category, setCategory] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [streamLink, setStreamLink] = useState("");
    const [active, setActive] = useState(false);
    const [icon, setIcon] = useState("");
    const [iconFile, setIconFile] = useState("");

    let {id} = useParams();
    const navigate = useNavigate();

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        setIconFile(file);

        reader.onload = () => {
            setIcon(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const submit = () => {
        const formData = new FormData();
        formData.append('category_id', category);
        formData.append('name', name);
        formData.append('description', description);
        formData.append('stream_link', streamLink);
        formData.append('active', active);
        formData.append('icon', iconFile);

        patch(`${process.env.REACT_APP_API_BASE_URL}/channels/${id}`, formData, setLoadingState).then((response) => {
            setCategory("");
            setName("");
            setDescription("");
            setStreamLink("");
            setActive(false);
            setIcon("");
            setIconFile("");
            toast.success("Updated Channel Successfully!");
            navigate('/channels');
        });
    };

    const getAllCategories = () => {
        get(`${process.env.REACT_APP_API_BASE_URL}/categories/all`, null, setLoadingState).then((response) => {
            setCategories(response.data);
            getData();
        });
    };

    const getData = () => {
        get(`${process.env.REACT_APP_API_BASE_URL}/channels/${id}`).then((response) => {
            const data = response.data.data;
            setCategory(data.category_id);
            setName(data.name);
            setDescription(data.description);
            setStreamLink(data.stream_link);
            setActive(data.active);
            setIcon(data.icon?.url);
        });
    };

    useEffect(() => {
        getAllCategories();
    }, []);

    return (
        <Layout>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-bold leading-6 text-slate-800">Edit Channel</h1>
                        <p className="mt-2 font-semibold text-slate-600">
                            Edit the currently selected channel.
                        </p>
                    </div>
                </div>
            </div>

            <div className="px-4 sm:px-6 lg:px-8 mt-12">

                <div className="inputs flex flex-col items-start gap-6 border-2 border-dashed rounded-md px-6 py-4">

                    <Select
                        label="Category"
                        options={categories || []}
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                    />

                    <Input
                        name="name"
                        id="name"
                        label="Name"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    <Input
                        name="description"
                        id="description"
                        label="Description"
                        placeholder="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />

                    <Input
                        name="stream_link"
                        id="stream_link"
                        label="Stream Link"
                        placeholder="https://example.com/playlist.m3u8"
                        value={streamLink}
                        onChange={(e) => setStreamLink(e.target.value)}
                    />

                    <FileUpload
                        label="Icon"
                        onChange={(e) => handleImageChange(e)}
                        image={icon}
                    />

                    <Checkbox
                        label="Active"
                        helperText="Determines whether to set this as active or not"
                        id="active"
                        name="active"
                        checked={active}
                        onChange={() => setActive(!active)}
                    />

                    <div className="mt-6">
                        <Button
                            label="Submit"
                            onClick={submit}
                        />
                    </div>


                </div>

            </div>
        </Layout>
    );
}

export default EditChannel;

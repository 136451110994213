export default function Input(props) {
    const {name, id, label, helperText, onChange, value, type = 'text', placeholder = 'Sample Input', labelColor = 'text-slate-800'} = props;

    return (
        <div>
            <label htmlFor={id} className={`block leading-6 font-bold mb-1 ${labelColor}`}>
                {label}
            </label>
            <div>
                <input
                    type={type}
                    name={name}
                    id={id}
                    className="transition ease-in-out duration-300 block w-full rounded-md border-0 py-1.5 text-slate-800 shadow-sm focus:shadow-md ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                />
            </div>
            {
                helperText ?
                    <p className="mt-2 text-sm text-slate-500" id="email-description">
                        {helperText}
                    </p> : null
            }
        </div>
    );
}

export default function IconButton(props) {
    const {Icon, onClick, size = 'large', style = 'default', type = 'button', disabled = false} = props;

    const _default = `bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${disabled ? '!bg-slate-400 !text-white' : null}`;
    const success = `bg-green-600 text-white hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 ${disabled ? '!bg-slate-400 !text-white' : null}`;
    const warning = `bg-yellow-600 text-white hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600 ${disabled ? '!bg-slate-400 !text-white' : null}`;
    const danger = `bg-red-600 text-white hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 ${disabled ? '!bg-slate-400 !text-white' : null}`;

    const sizeLarge = 'w-5 h-5';
    const sizeMedium = 'w-3 h-3';
    const sizeSmall = 'w-2 h-2';

    return (
        <button
            type={type}
            onClick={onClick}
            className={`transition ease-in-out duration-300 hover:shadow-md rounded-full p-2 shadow-sm ${style === 'default' ? _default : style === 'success' ? success : style === 'warning' ? warning : style === 'danger' ? danger : null}`}
        >
            <div
                className={`${size === 'large' ? sizeLarge : size === 'medium' ? sizeMedium : size === 'small' ? sizeSmall : null}`}>
                <Icon/>
            </div>
        </button>
    );
}

import ReactPlayer from 'react-player';
import {useLocation, useNavigate} from 'react-router-dom';
import IconButton from '../components/IconButton';
import {ArrowUturnLeftIcon} from '@heroicons/react/24/solid';
import {toast} from 'react-toastify';
import {get} from '../utils/axios.wrapper';
import PlayerLayout from '../components/PlayerLayout';

function Player() {
    const location = useLocation();
    const navigate = useNavigate();
    const {link, channel_id} = location.state || {};

    const handleOnError = () => {
        get(`${process.env.REACT_APP_API_BASE_URL}/channels/${channel_id}/has-error`, null).then((response) => {
            toast.error("Something went wrong while trying to play the stream!");
            navigate('/');
        });
    };

    const handleClearError = () => {
        get(`${process.env.REACT_APP_API_BASE_URL}/channels/${channel_id}/clear-error`, null).then((response) => {
        });
    };

    return (
        <PlayerLayout>
            <div style={{backgroundImage: "url('/images/bg.png')"}}
                 className="w-full h-screen bg-cover flex items-center justify-center relative">
                <div className="absolute w-full h-screen bg-black opacity-75 z-10"/>
                <div className="z-50 flex flex-col gap-2 items-start">
                    <IconButton
                        Icon={ArrowUturnLeftIcon}
                        onClick={() => navigate('/')}
                    />
                    <ReactPlayer url={link} controls playing={true} onError={handleOnError} onReady={handleClearError}/>
                </div>
            </div>
        </PlayerLayout>
    );
}

export default Player;

import Layout from '../components/Layout';
import {get} from '../utils/axios.wrapper';
import {useEffect, useState} from 'react';
import {useLoading} from '../utils/LoadingContext';

function Status() {
    const {setLoadingState} = useLoading();
    const [status, setStatus] = useState(true);
    const Good = () => (
        <div className="p-6 border rounded-full bg-green-400 text-white">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                 stroke="currentColor" className="w-32 h-32">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M5.25 14.25h13.5m-13.5 0a3 3 0 0 1-3-3m3 3a3 3 0 1 0 0 6h13.5a3 3 0 1 0 0-6m-16.5-3a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3m-19.5 0a4.5 4.5 0 0 1 .9-2.7L5.737 5.1a3.375 3.375 0 0 1 2.7-1.35h7.126c1.062 0 2.062.5 2.7 1.35l2.587 3.45a4.5 4.5 0 0 1 .9 2.7m0 0a3 3 0 0 1-3 3m0 3h.008v.008h-.008v-.008Zm0-6h.008v.008h-.008v-.008Zm-3 6h.008v.008h-.008v-.008Zm0-6h.008v.008h-.008v-.008Z"/>
            </svg>

        </div>
    );

    const Bad = () => (
        <div className="p-6 border rounded-full bg-red-400 text-white">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                 stroke="currentColor" className="w-32 h-32">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M5.25 14.25h13.5m-13.5 0a3 3 0 0 1-3-3m3 3a3 3 0 1 0 0 6h13.5a3 3 0 1 0 0-6m-16.5-3a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3m-19.5 0a4.5 4.5 0 0 1 .9-2.7L5.737 5.1a3.375 3.375 0 0 1 2.7-1.35h7.126c1.062 0 2.062.5 2.7 1.35l2.587 3.45a4.5 4.5 0 0 1 .9 2.7m0 0a3 3 0 0 1-3 3m0 3h.008v.008h-.008v-.008Zm0-6h.008v.008h-.008v-.008Zm-3 6h.008v.008h-.008v-.008Zm0-6h.008v.008h-.008v-.008Z"/>
            </svg>

        </div>
    );

    const getServerStatus = () => {
        get(`${process.env.REACT_APP_API_BASE_URL}`, null, setLoadingState).then(() => {
            setStatus(true);
        }).catch(() => {
            setStatus(false);
        });
    };

    useEffect(() => {
        getServerStatus();
    }, []);

    return (
        <Layout>
            <div className="w-full flex items-center justify-center">
                {
                    status ?
                        <Good/> : <Bad/>
                }
            </div>
        </Layout>
    );
}

export default Status;

import Pill from './Pill';
import IconButton from './IconButton';
import Button from './Button';

export default function Table(props) {
    const {getData, data = [], columns = [], actions = [], buttons = [], title, description, pagination} = props;

    const handlePageChange = (type) => {
        const page = pagination?.page;
        const params = {
            page: type === 'next' ? page + 1 : page - 1
        };
        getData(params);
    };

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-bold leading-6 text-slate-800">{title}</h1>
                    <p className="mt-2 font-semibold text-slate-600">
                        {description}
                    </p>
                </div>
                <div className="mt-4 flex flex-row flex-wrap gap-2">
                    {
                        buttons.map((button, index) => (
                            button?.icon ?
                                <IconButton key={`button_${index}`} Icon={button.icon} style={button?.style}
                                            onClick={button.onClick}/>
                                :
                                <Button label={button.label}
                                        style={button?.style}
                                        onClick={button.onClick}/>
                        ))
                    }
                </div>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                <tr>
                                    {
                                        columns.map((column) => (
                                            <th key={column.name}
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                {column.name}
                                            </th>
                                        ))
                                    }
                                    {
                                        actions.length ?
                                            <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                Actions
                                            </th> : null
                                    }
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {data.length ? data.map((datum, index) => (
                                    <tr key={`datum_${index}`}>
                                        {
                                            columns.map((column) => (
                                                column?.is_pill ?
                                                    <td key={column.id}
                                                        className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 flex items-start">
                                                        <Pill
                                                            label={datum[column?.id] === true ? 'Yes' : 'No'}
                                                            style={datum[column?.id] === true ? 'success' : 'warning'}
                                                        />
                                                    </td> :
                                                    <td key={column.id}
                                                        className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        {datum[column?.id]}
                                                    </td>
                                            ))
                                        }
                                        {
                                            actions.length ?
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 space-x-2">
                                                    {
                                                        actions.map((action, index) => (
                                                            action?.icon ?
                                                                <IconButton key={`action_${index}`} Icon={action.icon}
                                                                            style={action?.style}
                                                                            size={action?.size}
                                                                            onClick={() => action.onClick(datum)}/> : null
                                                        ))
                                                    }
                                                </td> : null
                                        }
                                    </tr>
                                )) : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {
                pagination ?
                    <nav
                        className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                        aria-label="Pagination"
                    >
                        <div className="flex flex-row items-center justify-center gap-2">
                            <p className="text-sm text-slate-700">
                                Page <span
                                className="font-bold">{pagination?.page}</span> of{' '}
                                <span className="font-bold">{pagination?.last_page}</span>
                            </p>
                            <p>|</p>
                            <p className="text-sm text-slate-700">
                                Showing <span
                                className="font-bold">{pagination?.size}</span> of{' '}
                                <span className="font-bold">{pagination?.total}</span> results
                            </p>
                        </div>
                        <div className="flex flex-1 justify-between gap-2 sm:justify-end">
                            <Button
                                label="Previous"
                                style="ghost"
                                disabled={pagination?.page <= 1}
                                onClick={() => handlePageChange('previous')}
                            />
                            <Button
                                label="Next"
                                style="ghost"
                                disabled={pagination?.page === pagination?.last_page}
                                onClick={() => handlePageChange('next')}
                            />
                        </div>
                    </nav> : null
            }
        </div>
    );
}

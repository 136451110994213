import {useEffect, useState} from 'react';
import Layout from '../../components/Layout';
import Input from '../../components/Input';
import Button from '../../components/Button';
import {get, patch} from '../../utils/axios.wrapper';
import {toast} from 'react-toastify';
import {useLoading} from '../../utils/LoadingContext';
import {useNavigate, useParams} from 'react-router-dom';

function EditUser() {
    const {setLoadingState} = useLoading();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    let {id} = useParams();
    const navigate = useNavigate();

    const submit = () => {
        const data = {
            name,
            email,
            username,
            password,
        };

        patch(`${process.env.REACT_APP_API_BASE_URL}/users/${id}`, data, setLoadingState).then((response) => {
            setName("");
            setEmail("");
            setUsername("");
            setPassword("");
            toast.success("Updated User Successfully!");
            navigate('/users');
        });
    };

    const getData = () => {
        get(`${process.env.REACT_APP_API_BASE_URL}/users/${id}`).then((response) => {
            const data = response.data.data;
            setName(data.name);
            setEmail(data.email);
            setUsername(data.username);
        });
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <Layout>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-bold leading-6 text-slate-800">Edit Category</h1>
                        <p className="mt-2 font-semibold text-slate-600">
                            Edit the currently selected category.
                        </p>
                    </div>
                </div>
            </div>

            <div className="px-4 sm:px-6 lg:px-8 mt-12">

                <div className="inputs flex flex-col items-start gap-6 border-2 border-dashed rounded-md px-6 py-4">

                    <Input
                        name="name"
                        id="name"
                        label="Name"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    <Input
                        name="username"
                        id="username"
                        label="Username"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />

                    <Input
                        name="email"
                        id="email"
                        label="Email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <Input
                        name="password"
                        id="password"
                        type="password"
                        label="Password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <div className="mt-6">
                        <Button
                            label="Submit"
                            onClick={submit}
                        />
                    </div>


                </div>

            </div>
        </Layout>
    );
}

export default EditUser;

export default function Select(props) {
    const {
        name,
        id,
        label,
        value,
        onChange,
        options = [],
        idKey = 'id',
        valueKey = 'name',
        labelColor = 'text-slate-800'
    } = props;

    return (
        <div>
            <label htmlFor={id} className={`block leading-6 font-bold mb-1 ${labelColor}`}>
                {label}
            </label>
            <select
                id={id}
                name={name}
                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-slate-800 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={onChange}
                value={value}
            >
                <option>Please select a value</option>
                {
                    options.map((option, index) => (
                        <option key={`options_${index}`} value={option[idKey]}>{option[valueKey]}</option>
                    ))
                }
            </select>
        </div>
    );
}

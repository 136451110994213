import {useEffect, useState} from 'react';
import Layout from '../../components/Layout';
import {useLoading} from '../../utils/LoadingContext';
import {get} from '../../utils/axios.wrapper';
import {useParams} from 'react-router-dom';
import Pill from '../../components/Pill';
import Button from '../../components/Button';
import {toast} from 'react-toastify';

function ShowChannel() {
    const {setLoadingState} = useLoading();
    const [data, setData] = useState(null);

    let {id} = useParams();

    const getData = () => {
        get(`${process.env.REACT_APP_API_BASE_URL}/channels/${id}`, null, setLoadingState).then((response) => {
            setData(response.data.data);
        });
    };

    const clearError = () => {
        get(`${process.env.REACT_APP_API_BASE_URL}/channels/${id}/clear-error`, null, setLoadingState).then((response) => {
            toast.success("Errors Cleared!");
            getData();
        });
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <Layout>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-bold leading-6 text-slate-800">Show Category</h1>
                        <p className="mt-2 font-semibold text-slate-600">
                            Here is the brief detail of your category.
                        </p>
                    </div>
                </div>
            </div>

            <div className="px-4 sm:px-6 lg:px-8 mt-12">

                <div className="inputs flex flex-col items-start gap-6 border-2 border-dashed rounded-md px-6 py-4">
                    <div className="border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-bold leading-6 text-slate-800">Name</dt>
                                <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0">{data?.name}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-bold leading-6 text-slate-800">Category</dt>
                                <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0">{data?.category?.name}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-bold leading-6 text-slate-800">Description</dt>
                                <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0">{data?.description}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-bold leading-6 text-slate-800">Stream Link</dt>
                                <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0">{data?.stream_link}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-bold leading-6 text-slate-800">Has Error</dt>
                                <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0 flex items-start">
                                    <Pill
                                        label={data?.has_error ? 'Yes' : 'No'}
                                        style={data?.has_error ? 'success' : 'warning'}
                                    />
                                </dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-bold leading-6 text-slate-800">Active</dt>
                                <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0 flex items-start">
                                    <Pill
                                        label={data?.active ? 'Yes' : 'No'}
                                        style={data?.active ? 'success' : 'warning'}
                                    />
                                </dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-bold leading-6 text-slate-800">Icon</dt>
                                <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0">
                                    <img src={data?.icon?.url} alt="Icon" className="h-12"/>
                                </dd>
                            </div>
                            {
                                data?.has_error ?
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-bold leading-6 text-slate-800">Clear Error</dt>
                                        <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0">
                                            <Button
                                                label="Clear Error"
                                                style="success"
                                                onClick={() => clearError()}
                                            />
                                        </dd>
                                    </div> : null
                            }
                        </dl>
                    </div>

                </div>

            </div>
        </Layout>
    );
}

export default ShowChannel;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/style.css';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import Status from './pages/Status';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Categories from './pages/Categories';
import Login from './pages/Login';
import CreateCategory from './pages/Categories/Create';
import {LoadingProvider} from './utils/LoadingContext';
import EditCategory from './pages/Categories/Edit';
import ShowCategory from './pages/Categories/Show';
import Channels from './pages/Channels';
import CreateChannel from './pages/Channels/Create';
import EditChannel from './pages/Channels/Edit';
import ShowChannel from './pages/Channels/Show';
import Users from './pages/Users';
import CreateUser from './pages/Users/Create';
import EditUser from './pages/Users/Edit';
import ShowUser from './pages/Users/Show';
import Play from './pages/Play';
import Player from './pages/Player';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Play/>,
    },
    {
        path: "/player",
        element: <Player/>,
    },
    {
        path: "/server-status",
        element: <Status/>,
    },
    {
        path: "/login",
        element: <Login/>,
    },

    // CATEGORIES
    {
        path: "/categories",
        element: <Categories/>,
    },
    {
        path: "/categories/create",
        element: <CreateCategory/>,
    },
    {
        path: "/categories/:id/edit",
        element: <EditCategory/>,
    },
    {
        path: "/categories/:id",
        element: <ShowCategory/>,
    },

    // CHANNELS
    {
        path: "/channels",
        element: <Channels/>,
    },
    {
        path: "/channels/create",
        element: <CreateChannel/>,
    },
    {
        path: "/channels/:id/edit",
        element: <EditChannel/>,
    },
    {
        path: "/channels/:id",
        element: <ShowChannel/>,
    },

    // USERS
    {
        path: "/users",
        element: <Users/>,
    },
    {
        path: "/users/create",
        element: <CreateUser/>,
    },
    {
        path: "/users/:id/edit",
        element: <EditUser/>,
    },
    {
        path: "/users/:id",
        element: <ShowUser/>,
    },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <LoadingProvider>
            <ToastContainer
                closeOnClick
                pauseOnHover
            />
            <RouterProvider router={router}/>
        </LoadingProvider>
    </>
);

import {ArrowPathIcon} from '@heroicons/react/24/solid';

export default function Loader(props) {
    return (
        <div className="absolute z-50 w-full h-screen bg-[rgba(0,0,0,0.5)] flex items-center justify-center">
            <div className="animate-spin text-indigo-300">
                <ArrowPathIcon className="w-24 h-24"/>
            </div>
        </div>
    );
}
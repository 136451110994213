import axios from "axios";
import {toast} from 'react-toastify';
import {history} from './router';

/**
 * Send Post Request
 *
 * @param url
 * @param data
 * @param setLoading
 */
export const post = async (url, data, setLoading = null) => {
    return await makeRequest('post', url, data, null, setLoading);
};

/**
 * Send Patch Request
 *
 * @param url
 * @param data
 * @param setLoading
 */
export const patch = async (url, data, setLoading = null) => {
    return await makeRequest('patch', url, data, null, setLoading);
};

/**
 * Send Delete Request
 *
 * @param url
 * @param setLoading
 */
export const del = async (url, setLoading = null) => {
    return await makeRequest('delete', url, null, null, setLoading);
};

/**
 * Get a response from a request
 *
 * @param url
 * @param params
 * @param setLoading
 */
export const get = async (url, params = null, setLoading = null) => {
    return await makeRequest('get', url, null, params, setLoading);
};

/**
 * Main make request method
 *
 * @param method
 * @param url
 * @param data
 * @param params
 * @param setLoading
 */
export const makeRequest = async (method, url, data = null, params = null, setLoading = null) => {
    if (setLoading) {
        setLoading(true);
    }
    const accessToken = localStorage.getItem('access_token');
    return axios.request({
        method,
        url,
        data,
        headers: {
            Authorization: `Bearer ${accessToken}`
        },
        params: params
    }).then((response) => {
        if (setLoading) {
            setLoading(false);
        }
        return response;
    }).catch((error) => {
        handleError(error);
        if (setLoading) {
            setLoading(false);
        }
        throw new Error();
    });
};

/**
 * Handle any errors
 *
 * @param error
 */
const handleError = async (error) => {
    if (!error?.response?.data) {
        toast.error(error.toString());
        return;
    }
    switch (error.response.data.statusCode) {
        case 401:
            await getNewAccessTokenWithRefreshToken();
            break;
        case 403:
            handleRedirectToPlay(error.response.data);
            break;
        default:
            toast.error(error.response.data.message);
            return;
    }
    toast.error(error.response.data.message);
};

/**
 * Get the new access token using the refresh token
 */
const getNewAccessTokenWithRefreshToken = async () => {
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
        history.navigate('/login', {replace: true});
        toast.error('Your session has expired. Please login again!');
        return;
    }

    post(`${process.env.REACT_APP_API_BASE_URL}/auth/login/refresh-token`, {refresh_token: refreshToken}).then(async (response) => {
        localStorage.setItem('user', JSON.stringify(response.data.data));
        localStorage.setItem('access_token', response.data.data.access_token);
        localStorage.setItem('refresh_token', response.data.data.refresh_token);
        history.navigate('/', {replace: true});
    }).catch(() => {
        toast.error('Your session has expired. Please login again!');
        history.navigate('/login', {replace: true});
    });
};

/**
 * Handles redirect to play
 *
 * @param data
 */
const handleRedirectToPlay = (data) => {
    if (data?.redirect) {
        history.navigate('/', {replace: true});
    }
};

/**
 * Get the current logged-in user
 *
 * @returns {any|null}
 */
export const getLoggedInUser = () => {
    const user = localStorage.getItem('user');
    if (user) {
        return JSON.parse(user);
    }
    return null;
};

/**
 * Logs the user out
 */
export const logout = () => {
    localStorage.setItem('user', null);
    localStorage.setItem('access_token', null);
    localStorage.setItem('refresh_token', null);
    history.navigate('/login', {replace: true});
};

/**
 * Checks if the user is SU or not
 *
 * @returns {boolean}
 */
export const isUserSu = () => {
    const user = getLoggedInUser();

    return !(!user || !user.is_su);
};
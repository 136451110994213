import {useEffect, useState} from 'react';
import Layout from '../../components/Layout';
import {useLoading} from '../../utils/LoadingContext';
import {get} from '../../utils/axios.wrapper';
import {useParams} from 'react-router-dom';

function ShowUser() {
    const {setLoadingState} = useLoading();
    const [data, setData] = useState(null);

    let {id} = useParams();

    const getData = () => {
        get(`${process.env.REACT_APP_API_BASE_URL}/users/${id}`, null, setLoadingState).then((response) => {
            setData(response.data.data);
        });
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <Layout>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-bold leading-6 text-slate-800">Show User</h1>
                        <p className="mt-2 font-semibold text-slate-600">
                            Here is the brief detail of the currently selected user.
                        </p>
                    </div>
                </div>
            </div>

            <div className="px-4 sm:px-6 lg:px-8 mt-12">

                <div className="inputs flex flex-col items-start gap-6 border-2 border-dashed rounded-md px-6 py-4">

                    <div className="border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-bold leading-6 text-slate-800">Name</dt>
                                <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0">{data?.name}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-bold leading-6 text-slate-800">Username</dt>
                                <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0">{data?.username}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-bold leading-6 text-slate-800">Email</dt>
                                <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0">{data?.email}</dd>
                            </div>
                        </dl>
                    </div>

                </div>

            </div>
        </Layout>
    );
}

export default ShowUser;

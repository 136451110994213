import * as lottieLoader from '../lottie/loader.json';
import Lottie from 'react-lottie';

export default function LottieLoader(props) {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: lottieLoader,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="absolute z-50 w-full h-screen bg-[rgba(0,0,0,0.5)] flex items-center justify-center">
            <Lottie options={defaultOptions}
                    height={800}
                    width={800}/>
        </div>
    );
}
import Layout from '../../components/Layout';
import {del, get} from '../../utils/axios.wrapper';
import {useEffect, useState} from 'react';
import Table from '../../components/Table';
import {EyeIcon, PencilSquareIcon, PlusIcon, TrashIcon} from '@heroicons/react/24/outline';
import {useNavigate} from 'react-router-dom';
import {useLoading} from '../../utils/LoadingContext';
import {toast} from 'react-toastify';
import DeleteModal from '../../components/DeleteModal';

function Categories() {
    const {setLoadingState} = useLoading();
    const [data, setData] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedDeleteItemId, setSelectedDeleteItemId] = useState("");

    const navigate = useNavigate();

    const getCategories = (params = null) => {
        get(`${process.env.REACT_APP_API_BASE_URL}/categories`, params, setLoadingState).then((response) => {
            setData(response.data);
        });
    };

    const deleteCategory = () => {
        del(`${process.env.REACT_APP_API_BASE_URL}/categories/${selectedDeleteItemId}`, setLoadingState).then((response) => {
            getCategories();
            setOpenDeleteModal(false);
            toast.success('Category deleted successfully!');
        });
    };

    const handleEdit = (data) => {
        navigate(`/categories/${data.id}/edit`);
    };

    const handleShow = (data) => {
        navigate(`/categories/${data.id}`);
    };

    const handleDelete = (data) => {
        setSelectedDeleteItemId(data.id);
        setOpenDeleteModal(true);
    };

    const columns = [
        {id: "name", name: "Name"},
        {id: "active", name: "Active", is_pill: true}
    ];

    const actions = [
        {icon: PencilSquareIcon, size: 'medium', onClick: handleEdit},
        {icon: EyeIcon, size: 'medium', onClick: handleShow},
        {icon: TrashIcon, size: 'medium', onClick: handleDelete, style: 'danger'},
    ];

    const buttons = [
        {icon: PlusIcon, onClick: () => navigate('/categories/create')}
    ];

    useEffect(() => {
        getCategories();
    }, []);

    return (
        <Layout>
            <Table
                title="Categories"
                description="A list of all your categories in one place"
                data={data?.data || []}
                columns={columns}
                actions={actions}
                pagination={data?.meta?.pagination}
                getData={(params) => getCategories(params)}
                buttons={buttons}
            />
            <DeleteModal
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                onConfirm={() => deleteCategory()}
            />
        </Layout>
    );
}

export default Categories;

import {post} from '../utils/axios.wrapper';
import {useState} from 'react';
import Input from '../components/Input';
import Button from '../components/Button';
import {useNavigate} from 'react-router-dom';
import {useLoading} from '../utils/LoadingContext';

function Login() {
    const {setLoadingState} = useLoading();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    const login = () => {
        const data = {
            username,
            password
        };

        post(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, data, setLoadingState).then((response) => {
            const user = response.data.data;
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('access_token', user.access_token);
            localStorage.setItem('refresh_token', user.refresh_token);
            navigate('/', { replace: true });
        });
    };

    return (
        <div className="w-full h-screen bg-cover" style={{backgroundImage: "url('/images/bg.png')"}}>

            <div className="content p-24 flex flex-col justify-between items-center h-screen">

                <div>
                    <img src="/images/logo.png" alt="Logo" className="w-32 h-32"/>
                </div>

                <div className="inputs flex flex-col gap-6">

                    <div className="px-8 py-6 rounded-md bg-[rgba(0,0,0,0.5)] text-center flex flex-col gap-2">
                        <h6 className="font-bold text-3xl text-white">Login</h6>
                        <p className="text-md text-white">Enter your credentials to login</p>
                    </div>

                    <div className="input-container flex flex-col gap-4">
                        <Input
                            name="username"
                            id="username"
                            label="Username"
                            placeholder="Username"
                            onChange={(e) => setUsername(e.target.value)}
                        />

                        <Input
                            name="password"
                            id="password"
                            label="Password"
                            placeholder="Password"
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        <Button
                            onClick={login}
                            label="Login"
                        />
                    </div>

                </div>

                <div>

                </div>

            </div>

        </div>
    );
}

export default Login;

import {useState} from 'react';
import Layout from '../../components/Layout';
import Input from '../../components/Input';
import FileUpload from '../../components/FileUpload';
import Button from '../../components/Button';
import {post} from '../../utils/axios.wrapper';
import {toast} from 'react-toastify';
import {useLoading} from '../../utils/LoadingContext';
import Checkbox from '../../components/Checkbox';
import {useNavigate} from 'react-router-dom';

function CreateCategory() {
    const {setLoadingState} = useLoading();
    const [name, setName] = useState("");
    const [active, setActive] = useState(false);
    const [icon, setIcon] = useState("");
    const [iconFile, setIconFile] = useState("");

    const navigate = useNavigate();

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        setIconFile(file);

        reader.onload = () => {
            setIcon(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const submit = () => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('active', active);
        formData.append('icon', iconFile);

        post(`${process.env.REACT_APP_API_BASE_URL}/categories`, formData, setLoadingState).then((response) => {
            setName("");
            setActive(false);
            setIcon("");
            setIconFile("");
            toast.success("Created Category Successfully!");
            navigate('/categories');
        });
    };

    return (
        <Layout>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-bold leading-6 text-slate-800">Create Category</h1>
                        <p className="mt-2 font-semibold text-slate-600">
                            Create a category of your liking. Tip: You may with to add an icon if you wish.
                        </p>
                    </div>
                </div>
            </div>

            <div className="px-4 sm:px-6 lg:px-8 mt-12">

                <div className="inputs flex flex-col items-start gap-6 border-2 border-dashed rounded-md px-6 py-4">

                    <Input
                        name="name"
                        id="name"
                        label="Name"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    <FileUpload
                        label="Icon"
                        onChange={(e) => handleImageChange(e)}
                        image={icon}
                    />

                    <Checkbox
                        label="Active"
                        helperText="Determines whether to set this as active or not"
                        id="active"
                        name="active"
                        checked={active}
                        onChange={() => setActive(!active)}
                    />

                    <div className="mt-6">
                        <Button
                            label="Submit"
                            onClick={submit}
                        />
                    </div>


                </div>

            </div>
        </Layout>
    );
}

export default CreateCategory;

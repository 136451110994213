export default function Pill(props) {
    const {label, style = 'default'} = props;

    const success = 'bg-green-300 text-green-800 border-green-800';
    const warning = 'bg-yellow-300 text-yellow-800 border-yellow-800';
    const danger = 'bg-red-300 text-red-800 border-red-800';
    const _default = 'bg-white text-slate-800';

    return (
        <div className={`px-2 py-1 font-bold rounded-xl text-xs border ${style === 'default' ? _default : style === 'success' ? success : style === 'warning' ? warning : style === 'danger' ? danger : null}`}>
            {label}
        </div>
    )
}
